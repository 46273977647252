/* You can add global styles to this file, and also import other style files */
@use '~@angular/material' as mat;

@import url("https://fonts.googleapis.com/css?family=Roboto|Open+Sans");

/**
* Generated theme by Material Theme Generator
* https://materialtheme.arcsine.dev
* Fork at: https://materialtheme.arcsine.dev/?c=YHBhbGV0dGU$YHByaW1hcnk$YF48IzY5NGVhNSIsIj9lcjwjZDJjYWU0IiwiO2VyPCM0YzM1OGF$LCIlPmBePCMwOGJlYjIiLCI~ZXI8I2I1ZWNlOCIsIjtlcjwjMDRhNzk5fiwid2Fybj5gXjwjZmYwMDAwIiwiP2VyPCNmZmIzYjMiLCI7ZXI8I2ZmMDAwMH4sIj9UZXh0PCMwMDAwMDAiLCI~PTwjZmFmYWZhIiwiO1RleHQ8I2ZmZmZmZiIsIjs9PCMxNzExMmZ$LCJmb250cz5bYEA8KC00fixgQDwoLTN$LGBAPCgtMn4sYEA8KC0xfixgQDxoZWFkbGluZX4sYEA8dGl0bGV$LGBAPHN1YiktMn4sYEA8c3ViKS0xfixgQDxib2R5LTJ$LGBAPGJvZHktMX4sYEA8YnV0dG9ufixgQDxjYXB0aW9ufixgQDxpbnB1dCIsInNpemU$bnVsbH1dLCJpY29uczxUd29Ub25lIiwiP25lc3M$dHJ1ZSwidmVyc2lvbj4xMX0=
*/


// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.

// Fonts
@import "https://fonts.googleapis.com/css?family=Material+Icons+Two+Tone";
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500");

$fontConfig: (
    display-4: mat.define-typography-level(112px, 112px, 300, "Roboto", -0.0134em),
    display-3: mat.define-typography-level(56px, 56px, 400, "Roboto", -0.0089em),
    display-2: mat.define-typography-level(45px, 48px, 400, "Roboto", 0em),
    display-1: mat.define-typography-level(34px, 40px, 400, "Roboto", 0.0074em),
    headline: mat.define-typography-level(24px, 32px, 400, "Roboto", 0em),
    title: mat.define-typography-level(20px, 32px, 500, "Roboto", 0.0075em),
    subheading-2: mat.define-typography-level(16px, 28px, 400, "Roboto", 0.0094em),
    subheading-1: mat.define-typography-level(15px, 24px, 500, "Roboto", 0.0067em),
    body-2: mat.define-typography-level(14px, 24px, 500, "Roboto", 0.0179em),
    body-1: mat.define-typography-level(14px, 20px, 400, "Roboto", 0.0179em),
    button: mat.define-typography-level(14px, 14px, 500, "Roboto", 0.0893em),
    caption: mat.define-typography-level(12px, 20px, 400, "Roboto", 0.0333em),
    input: mat.define-typography-level(inherit, 1.125, 400, "Roboto", 1.5px),
);

// Foreground Elements

// Light Theme Text
$dark-text: #000000;
$dark-primary-text: rgba($dark-text, 0.87);
$dark-accent-text: rgba(rgba(black, 0.87), 0.54);
$dark-disabled-text: rgba(rgba(black, 0.87), 0.38);
$dark-dividers: rgba(rgba(black, 0.87), 0.12);
$dark-focused: rgba(rgba(black, 0.87), 0.12);

mat.$light-theme-foreground-palette: (
    base: black,
    divider: rgba(black, 0.12),
    dividers: rgba(black, 0.12),
    disabled: rgba(black, 0.38),
    disabled-button: rgba($dark-text, 0.26),
    disabled-text: rgba(black, 0.38),
    elevation: black,
    secondary-text: $dark-accent-text,
    hint-text: rgba(black, 0.38),
    accent-text: $dark-accent-text,
    icon: $dark-accent-text,
    icons: $dark-accent-text,
    text: rgba(black, 0.87),
    slider-min: rgba(black, 0.87),
    slider-off: rgba($dark-text, 0.26),
    slider-off-active: rgba(black, 0.38),
);

// Dark Theme text
$light-text: #ffffff;
$light-primary-text: $light-text;
$light-accent-text: rgba(white, 0.7);
$light-disabled-text: rgba(white, 0.5);
$light-dividers: rgba(white, 0.12);
$light-focused: rgba(white, 0.12);

mat.$dark-theme-foreground-palette: (
    base: $light-text,
    divider: rgba(white, 0.12),
    dividers: rgba(white, 0.12),
    disabled: rgba(white, 0.5),
    disabled-button: rgba($light-text, 0.3),
    disabled-text: rgba(white, 0.5),
    elevation: black,
    hint-text: rgba(white, 0.5),
    secondary-text: $light-accent-text,
    accent-text: $light-accent-text,
    icon: $light-text,
    icons: $light-text,
    text: $light-text,
    slider-min: $light-text,
    slider-off: rgba($light-text, 0.3),
    slider-off-active: rgba($light-text, 0.3),
);

// Background config
// Light bg
$light-background: #fafafa;
$light-bg-darker-5: darken($light-background, 5%);
$light-bg-darker-10: darken($light-background, 10%);
$light-bg-darker-20: darken($light-background, 20%);
$light-bg-darker-30: darken($light-background, 30%);
$light-bg-lighter-5: lighten($light-background, 5%);
$dark-bg-tooltip: lighten(#17112f, 20%);
$dark-bg-alpha-4: rgba(#17112f, 0.04);
$dark-bg-alpha-12: rgba(#17112f, 0.12);

mat.$light-theme-background-palette: (
    background: $light-background,
    status-bar: $light-bg-darker-20,
    app-bar: $light-bg-darker-5,
    hover: $dark-bg-alpha-4,
    card: $light-bg-lighter-5,
    dialog: $light-bg-lighter-5,
    tooltip: $dark-bg-tooltip,
    disabled-button: $dark-bg-alpha-12,
    raised-button: $light-bg-lighter-5,
    focused-button: rgba(black, 0.12),
    selected-button: $light-bg-darker-20,
    selected-disabled-button: $light-bg-darker-30,
    disabled-button-toggle: $light-bg-darker-10,
    unselected-chip: $light-bg-darker-10,
    disabled-list-option: $light-bg-darker-10,
);

// Dark bg
$dark-background: #17112f;
$dark-bg-lighter-5: lighten($dark-background, 5%);
$dark-bg-lighter-10: lighten($dark-background, 10%);
$dark-bg-lighter-20: lighten($dark-background, 20%);
$dark-bg-lighter-30: lighten($dark-background, 30%);
$light-bg-alpha-4: rgba(#fafafa, 0.04);
$light-bg-alpha-12: rgba(#fafafa, 0.12);

// Background palette for dark themes.
mat.$dark-theme-background-palette: (
    background: $dark-background,
    status-bar: $dark-bg-lighter-20,
    app-bar: $dark-bg-lighter-5,
    hover: $light-bg-alpha-4,
    card: $dark-bg-lighter-5,
    dialog: $dark-bg-lighter-5,
    tooltip: $dark-bg-lighter-20,
    disabled-button: $light-bg-alpha-12,
    raised-button: $dark-bg-lighter-5,
    focused-button: rgba(white, 0.12),
    selected-button: $dark-bg-lighter-20,
    selected-disabled-button: $dark-bg-lighter-30,
    disabled-button-toggle: $dark-bg-lighter-10,
    unselected-chip: $dark-bg-lighter-20,
    disabled-list-option: $dark-bg-lighter-10,
);

// Compute font config
@include mat.core($fontConfig);

// Theme Config

body {
    --primary-color: #694ea5;
    --primary-lighter-color: #d2cae4;
    --primary-darker-color: #4c358a;
    --text-primary-color: #{white};
    --text-primary-lighter-color: #{rgba(black, 0.87)};
    --text-primary-darker-color: #{white};
}
$mat-primary: (
    main: #694ea5,
    lighter: #d2cae4,
    darker: #4c358a,
    200: #694ea5,
    // For slide toggle,
    contrast:
        (
            main: white,
            lighter: rgba(black, 0.87),
            darker: white,
        ),
);
$theme-primary: mat.define-palette($mat-primary, main, lighter, darker);

body {
    --accent-color: #08beb2;
    --accent-lighter-color: #b5ece8;
    --accent-darker-color: #04a799;
    --text-accent-color: #{rgba(black, 0.87)};
    --text-accent-lighter-color: #{rgba(black, 0.87)};
    --text-accent-darker-color: #{white};
}
$mat-accent: (
    main: #08beb2,
    lighter: #b5ece8,
    darker: #04a799,
    200: #08beb2,
    // For slide toggle,
    contrast:
        (
            main: rgba(black, 0.87),
            lighter: rgba(black, 0.87),
            darker: white,
        ),
);
$theme-accent: mat.define-palette($mat-accent, main, lighter, darker);

body {
    --warn-color: #ff0000;
    --warn-lighter-color: #ffb3b3;
    --warn-darker-color: #ff0000;
    --text-warn-color: #{white};
    --text-warn-lighter-color: #{rgba(black, 0.87)};
    --text-warn-darker-color: #{white};
}
$mat-warn: (
    main: #ff0000,
    lighter: #ffb3b3,
    darker: #ff0000,
    200: #ff0000,
    // For slide toggle,
    contrast:
        (
            main: white,
            lighter: rgba(black, 0.87),
            darker: white,
        ),
);
$theme-warn: mat.define-palette($mat-warn, main, lighter, darker);

$theme: mat.define-light-theme($theme-primary, $theme-accent, $theme-warn);
$altTheme: mat.define-dark-theme($theme-primary, $theme-accent, $theme-warn);

// Theme Init
@include mat.all-component-themes($theme);

.theme-alternate {
    @include mat.all-component-themes($altTheme);
}

// Specific component overrides, pieces that are not in line with the general theming

// Handle buttons appropriately, with respect to line-height
.mat-raised-button,
.mat-stroked-button,
.mat-flat-button {
    padding: 0 1.15em;
    margin: 0 0.65em;
    min-width: 3em;
    line-height: 36.4px;
}

.mat-standard-chip {
    padding: 0.5em 0.85em;
    min-height: 2.5em;
}

.material-icons {
    font-size: 24px;
    font-family: "Material Icons Two Tone", "Material Icons";
    .mat-badge-content {
        font-family: "Roboto";
    }
}

html,
body {
    padding: 0;
    margin: 0;
    border: 0;
    font-family: "Roboto", "Open Sans";
}

.card {
    margin-top: 18px;
    max-width: 538px;
    width: 100%;
    border-radius: 3px;
    background-color: #ffffff;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.3);
    .row {
        display: flex;
        padding: 0 12px;
        display: flex;
        justify-content: center;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: normal;
        &.first {
            justify-content: space-between;
            font-size: 14px;
            font-weight: normal;
            line-height: normal;
            color: #a6a6a6;
        }
    }
}
